<template>
  <div>
    <!-- Page Heading -->
    <div class="ps-form__header">
      <h3>Edit Product</h3>
    </div>

    <!-- Content Row -->
    <ProductForm :product-id="$route.params.id"></ProductForm>

  </div>
</template>

<script>
import ProductForm from "./ProductForm";
export default {
  name: "EditMerchantProduct",
  components: {ProductForm},
}
</script>

<style scoped>

</style>